<template>
  <div class="messages-container">
    <div class="messages-header">Mesajlar</div>
    <div class="page-info">
      <span>
        <b>1:</b> Kullanıcılar tarafından gelen mesajlar bu sayfada gösterilir.
      </span>
      <span>
        <b>2:</b> Mesajı silmek için, mesaj kutusunun sağ köşesindeki çarpı(X)
        butonuna basınız.</span
      >
      <span>
        <b>3:</b> Dikkat! Silinen mesaj geri kurtarılamaz. Silmeden önce kontrol
        ediniz.</span
      >
    </div>
    <div class="message" v-for="item in messages" :key="item._id">
      <span class="delete-button"
        ><i class="fas fa-times" @click="deleteMessage(item._id)"></i
      ></span>

      <div class="message-side">
        <span><b>Gönderen adı:</b> {{ item.name }} </span>
        <span v-if="item.phone"
          ><b>Gönderen telefonu:</b> {{ item.phone }}
        </span>
        <span><b>Gönderen mail:</b> {{ item.email }} </span>
      </div>
      <div class="message-side">
        <span><b>Gönderim Tarihi:</b> {{ item.date | setDate }} </span>
        <span><b>Mesaj:</b> {{ item.message }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Messages",
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("getMessages");
  },
  computed: {
    messages() {
      return this.$store.state.message.allMessages;
    },
  },
  methods: {
    deleteMessage(id) {
      this.$store.dispatch("deleteMessage", id);
    },
  },
  filters: {
    setDate: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.slice(0, 10);
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";

.messages-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .page-info {
    border: 2px solid #a8a7a7;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: bisque;
  }
  .messages-header {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #777777;
    margin-bottom: 1rem;
    color: #777777;
  }
  .message {
    width: 100%;
    height: auto;
    border: 2px solid #a8a7a7;
    border-radius: 10px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    display: flex;
    position: relative;
    @include md {
      flex-direction: column;
    }
    @include sm {
      flex-direction: column;
    }
    @include xs {
      flex-direction: column;
    }
    .delete-button {
      position: absolute;
      right: 5px;
      top: 0;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
    .message-side {
      padding: 1rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      @include md {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }
      @include xs {
        width: 100%;
      }
    }
  }
}
</style>
