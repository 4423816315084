<template>
  <div class="set-product-container">
    <div class="set-product-header">Ürün Düzenle</div>
    <div class="page-info">
      <span
        ><b>1:</b> Ürün bilgilerini düzenlemek için bu sekmeyi kullanınız</span
      >
      <span><b>2:</b> Bu menüden ürünün teknik özellikleri düzenlememektedir. Ürün ekleme menüsünden eklemelisiniz. </span>
    </div>
    <div class="set-input-group">
      <select v-model="chooseCategory" @change="takeProducts()">
        <option value="" disabled>
          Düzenlenecek ürünün kategorisini seçiniz
        </option>
        <option :value="item._id" v-for="item in categories" :key="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>

    <div class="set-input-group" v-if="chooseCategory">
      <select v-model="chooseProduct" @change="getDetail()">
        <option value="" disabled>Düzenlenecek ürünü seçiniz</option>
        <option :value="item.id" v-for="item in products" :key="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
    <form @submit.prevent="updateProduct()">
      <div class="set-details" v-if="detail && chooseProduct">
        <div class="set-input-group">
          <label>Ürün Adı</label>
          <input type="text" v-model="detail.name" />
        </div>
        <div class="set-input-group">
          <label>Ürün Kodu</label>
          <input type="text" v-model="detail.code" />
        </div>
        <div class="set-input-group">
          <label>Ürün Kategorisi</label>
          <select v-model="detail.category">
            <option
              v-for="item in categories"
              :key="item._id"
              :value="item._id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="set-input-group" v-if="detail.properties.length > 0">
          <label>Ürün Özellikleri</label>
          <div
            class="property-input-group"
            v-for="(item, index) in detail.properties"
            :key="index"
          >
            <span class="item-title">{{ index + 1 }}. Özellik:</span>
            <input
              type="text"
              class="property-input"
              v-model="detail.properties[index]"
            />
            <span class="delete"><i class="fas fa-times"></i></span>
          </div>
          
        </div>
        <div class="set-input-group">
          <label>Ürün Resmi</label>
          <img :src="detail.image | setImage" v-if="imagePreview == null" />

          <img :src="imagePreview" />
          <input
            type="file"
            @change="onChange"
            ref="imageFile"
            accept="image/*"
            id="imageFile"
          />
          {{ detail.image.name }}
          <label class="image-label" for="imageFile">Resim Seç</label>
        </div>
        <div class="set-input-group">
          <label>Teknik Resim </label>
          <span v-if="!detail.technicImage">Teknik resim yok</span>
          <img
            :src="detail.technicImage | setImage"
            v-if="technicalPreview == null && detail.technicImage"
          />
          <img :src="technicalPreview" alt="" />
          <input
            type="file"
            ref="technicFile"
            id="technicFile"
            accept="image/*"
            @change="setTechnic"
          />
          <label class="image-label" for="technicFile">Resim Seç</label>
          <label class="image-label" v-if="detail.technicImage" @click="detail.technicImage = ''">Resmi Sil</label>
        </div>
        <div class="button-group">
          <button type="submit" class="update-button">Güncelle</button>
        </div>
     
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SetProduct",
  data() {
    return {
      chooseCategory: "",
      chooseProduct: "",
      imagePreview: null,
      technicalPreview: null,
    };
  },
  created() {
    this.$store.dispatch("getCategories");
  },
  computed: {
    categories() {
      return this.$store.state.categories.navbarItems;
    },
    products() {
      return this.$store.state.products.productNames;
    },
    detail() {
      return this.$store.state.products.productDetail.product;
    },
  },
  methods: {
    takeProducts() {
      (this.chooseProduct = ""),
        this.$store.dispatch("getProductNames", this.chooseCategory);
    },
    getDetail() {
      this.$store.dispatch("getProductDetail", this.chooseProduct);
    },
    onChange() {
      this.detail.image = this.$refs.imageFile.files[0];

      this.imagePreview = URL.createObjectURL(this.detail.image);
    },
    setTechnic() {
      this.detail.technicImage = this.$refs.technicFile.files[0];
      this.technicalPreview = URL.createObjectURL(this.detail.technicImage);
    },
    updateProduct() {
      let technicID = this.detail.technicImageID ? this.detail.technicImageID : "";
      let formData = new FormData();
      formData.append("image", this.detail.image);
      formData.append("technicImage", this.detail.technicImage);
      formData.append("imageID", this.detail.imageID);
      formData.append("technicImageID", technicID);
      formData.append("name", this.detail.name);
      formData.append("code", this.detail.code);
      formData.append("category", this.detail.category);
      formData.append("properties", this.detail.properties);
      
      this.$store.dispatch("updateProduct", {
        data: formData,
        id: this.chooseProduct,
      });
    },
  },
  filters: {
    setImage: function (value) {
      if (!value) return "";
      return `${process.env.VUE_APP_IMAGEURL_ENDPOINT}/${value}?tr=w-500,h-400,cm-pad_resize,bg-000`;
    },
  },
};
</script>

<style lang="scss">
.set-product-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .page-info {
    border: 2px solid #a8a7a7;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: bisque;
  }
  .set-product-header {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #777777;
    margin-bottom: 1rem;
    color: #777777;
  }
  .set-input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    border: 2px solid #e0e0e0;
    color: #777777;
    border-radius: 10px;
    padding: 1rem;
    .image-label {
      width: 150px;
      height: 40px;
      background-color: orangered;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      z-index: 2;
      transition: 0.3s ease;
      &:hover {
        background-color: rgb(201, 56, 4);
      }
    }
    input[type="file"] {
      display: none;
    }
    input[type="text"],
    select {
      width: 100%;
      height: 40px;
      outline: none;
      border: #e5e5e5 solid 1px;
      border-radius: 10px;
      padding-left: 10px;
      color: #777777;
      font-weight: 500;
      &:focus {
        border-color: orangered;
      }
    }
    img {
      max-width: 250px;
    }
    label {
      color: #777777;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .property-input-group {
      display: flex;
      margin-bottom: 1rem;
      width: auto;
      .item-title {
        display: flex;
        align-items: center;
        color: #777777;
        font-size: 0.9rem;
        font-weight: 500;
        width: 10%;
        margin-right: 1rem;
      }
      input[type="text"] {
        width: 50%;
      }
      .delete {
        color: red;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .set-details {
    padding: 1rem 0;
    border-top: #777777 1px solid;
  }
  .button-group{
    .update-button{
      width: 150px;
      height: 40px;
      background-color: orangered;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      outline: none;
      border: none;
      z-index: 2;
      transition: 0.3s ease;
      &:hover {
        background-color: rgb(201, 56, 4);
      }
    }
  }
}
</style>
