<template>
  <div class="add-product-container">
    <div class="add-product-header">Ürün Ekle</div>
    <div class="page-info">
      <span> <b>1:</b> Yeni bir ürün eklemek için bu sayfayı kullanınız. </span>
      <span> <b>2:</b> Kırmızı kenarlıklı alanlar zorunlu alanlardır.</span>
      <span>
        <b>3:</b> Ürün resmi 2MB'dan fazla olmamalı ve resim uzantısı 'JPG' veya
        'PNG' olmalıdır.</span
      >
      <span>
        <b>4:</b> Ürün teknik özelliklerini ayrı ayrı 'Yeni özellik ekle'
        butonunu kullanarak ekleyiniz.</span
      >
      <span
        ><b>5:</b> Teknik özelliklerde yer alacak özel simgeler için butonları
        kullanabilirsiniz.</span
      >
    </div>
    <form enctype="multipart/form-data" @submit.prevent="addProduct">
      <div class="add-product-input-group">
        <div class="section-group">
          <span class="section-title">Ürün Kategorisi</span>
          <select
            name="categoriler"
            v-model="categoryID"
            @change="getCurrentProducts(categoryID)"
          >
            <option value="" disabled>Lütfen bir kategori seçiniz</option>
            <option
              v-for="(item, index) in categories"
              :key="index"
              :value="item._id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="section-group" v-if="categoryID">
          <span class="section-title">Kategorideki ekli ürünler </span>

          <div v-for="(item, index) in addedProducts" :key="item._id">
            <span>
              <b>{{ index + 1 }}:</b>
            </span>
            <span>{{ item.name }}</span>
          </div>

          <div v-if="categoryID && addedProducts == []">
            Seçtiğiniz kategoride kayıtlı ürün yok
          </div>
        </div>
      </div>

      <div class="add-product-input-group">
        <div class="section-group">
          <span class="section-title">Ürün Adı Ör: Lama Cam Tutucu</span>
          <input
            type="text"
            placeholder="Ürün Adı"
            v-model="product.name"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="add-product-input-group">
        <div class="section-group">
          <span class="section-title">Ürün Kodu Ör: ÇP - PK</span>
          <input
            type="text"
            placeholder="Ürün Kodu"
            v-model="product.code"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="add-product-input-group">
        <div class="section-group">
          <span class="section-title"
            >Ürün Teknik Özellikleri - Ör: Ø51 - 46</span
          >

          <div
            class="property-input-group"
            v-for="(item, index) in product.properties"
            :key="index"
          >
            <span class="section-title">Özellik {{ index + 1 }}: </span>
            <input
              type="text"
              placeholder="Ürün özelliği"
              :id="'propertyRef' + index"
              v-model="product.properties[index]"
              @focus="activeInput = index"
              autocomplete="off"
              class="property-input"
            />
            <span class="delete-button" @click="deleteProperty(index)"
              >Sil</span
            >
          </div>
          <div class="button-group">
            <button
              class="property-button"
              @click.prevent="product.properties.push('')"
            >
              Yeni özellik ekle
            </button>

            <button
              class="property-button"
              @click.prevent="addDiameterIcon('cap')"
            >
              Ø Çap
            </button>
            <button
              class="property-button"
              @click.prevent="addDiameterIcon('derece')"
            >
              ° Derece
            </button>
            <span class="diameter-message"
              >-> İşareti eklemek için tıklayın</span
            >
          </div>
        </div>
      </div>
      <div class="add-product-input-group">
        <div class="section-group">
          <span class="section-title">Ürün Resmi</span>
          <div class="image-input-group">
            <label class="image-label" for="imageFile">Resim Seç</label>

            <input
              class="inputfile"
              type="file"
              id="imageFile"
              ref="imageFile"
              @change="setImage"
            />
            <span v-if="product.image !== null">
              {{ product.image.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="add-product-input-group">
        <div class="section-group">
          <span class="section-title">Teknik Resim</span>
          <div class="image-input-group">
            <label class="image-label" for="technicImage">Resim Seç</label>
            <input
              type="file"
              class="inputfile"
              ref="technicImage"
              id="technicImage"
              @change="setTechnic"
            />
          </div>
        </div>
      </div>
      <div class="add-product-button-group">
        <div class="loader" v-if="loaderStatus"></div>
        <div class="add-message">{{ addMessage }}</div>
        <button class="add-product-button">Yeni Ürün Ekle</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AddProduct",
  data() {
    return {
      categoryID: "",
      product: {
        name: "",
        code: "",
        image: null,
        properties: [""],
        technicImage: null,
      },
      addMessage: "",
      activeInput: 0,
      loaderStatus: false,
    };
  },
  created() {
    this.$store.dispatch("getCategories");
  },
  computed: {
    categories() {
      return this.$store.state.categories.navbarItems;
    },
    addedProducts() {
      return this.$store.state.admin.productsInCategory;
    },
    addStatus() {
      return this.$store.state.admin.productAddStatus;
    },
  },
  methods: {
    setImage() {
      this.product.image = this.$refs.imageFile.files[0];
      console.log(this.product.image);
    },
    setTechnic() {
      this.product.technicImage = this.$refs.technicImage.files[0];
    },
    addProduct() {
      const formData = new FormData();
      formData.append("image", this.product.image);
      formData.append("technicImage", this.product.technicImage);
      formData.append("name", this.product.name);
      formData.append("code", this.product.code);
      formData.append("category", this.categoryID);
      formData.append("properties", this.product.properties);

      if (
        this.product.image &&
        this.product.name &&
        this.product.code &&
        this.categoryID
      ) {
        this.loaderStatus = true;
        this.$store.dispatch("addNewProduct", formData).then(() => {
          if (this.$store.state.admin.productAddStatus) {
            this.addMessage = "Ürün başarıyla eklendi";
            this.loaderStatus = false;
            this.product.image = null;
            this.product.name = "";
            this.product.code = "";
            this.product.properties = [""];
            this.categoryID = "";
          } else {
            this.addMessage = "Ürün eklenirken bir hata oluştu";
          }
        });
      }
    },

    addDiameterIcon(val) {
      let diameter = "Ø";
      let degree = "°";
      let input = document.getElementById("propertyRef" + this.activeInput);
      let icon = "";
      if (val == "cap") icon = diameter;
      else if (val == "derece") icon = degree;
      input.value += icon;
      this.product.properties[this.activeInput] += icon;
      input.focus();
    },
    deleteProperty(val) {
      this.product.properties.splice(val, 1);
    },
    getCurrentProducts(val) {
      this.$store.dispatch("getProductsInCategory", val);
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";

.add-product-container {
  width: 100%;
  height: 100%;
  .add-product-header {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #777777;
    margin-bottom: 1rem;
    color: #777777;
  }
  .page-info {
    border: 2px solid #a8a7a7;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: bisque;
  }
  .add-product-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    border: 2px solid red;
    color: #777777;
    border-radius: 10px;
    &:nth-child(4),
    &:nth-child(6) {
      border-color: #e0e0e0;
    }
    @include md {
      flex-direction: column;
    }
    @include sm {
      flex-direction: column;
    }
    @include xs {
      flex-direction: column;
    }

    .section-group {
      width: calc(50% - 1rem);
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .section-title {
        font-weight: bold;
        margin-bottom: 1rem;
      }
      @include md {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }
      @include xs {
        width: 100%;
      }
    }

    .image-input-group {
      display: flex;
      height: 50px;
      align-items: center;
      position: relative;
      width: auto;
      .inputfile {
        overflow: hidden;
        position: absolute;
        z-index: -1;
        right: -213px;
        display: none;
      }

      .image-label {
        width: 150px;
        height: 40px;
        background-color: orangered;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        z-index: 2;
        transition: 0.3s ease;
        &:hover {
          background-color: rgb(201, 56, 4);
        }
      }
    }
    select {
      height: 40px;
      outline: none;
      border: #e5e5e5 solid 1px;
      border-radius: 10px;
      padding-left: 10px;
      color: #777777;
    }
    input[type="text"] {
      height: 40px;
      outline: none;
      border: #e5e5e5 solid 1px;
      border-radius: 10px;
      padding-left: 10px;
    }

    .property-input {
      margin-bottom: 10px;
      margin-left: 10px;
      padding-left: 10px;
    }

    .button-group {
      display: flex;
      height: 50px;
      align-items: center;
      margin-top: 1rem;
      width: 100%;
      .property-button {
        width: 75px;
        height: 40px;
        background-color: orangered;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        border: none;
        outline: none;
        margin-right: 10px;
        transition: 0.3s ease;
        &:hover {
          background-color: rgb(201, 56, 4);
        }
        &:nth-child(1) {
          width: 150px;
          margin-right: 1rem;
        }
      }
    }
  }
  .delete-button {
    margin-left: 10px;
    color: red;
    cursor: pointer;
  }
  .add-product-button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .add-product-button {
      width: 250px;
      height: 50px;
      border: none;
      background-color: orangered;
      border-radius: 10px;
      color: white;
      margin-top: 1rem;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        background-color: rgb(201, 56, 4);
      }
    }
    .loader {
      border: 4px solid #f3f3f3; /* Light grey */
      border-top: 4px solid orangered; /* Blue */
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
