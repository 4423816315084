<template>
  <div class="delete-product-container">
    <div class="delete-product-header">Ürün Sil</div>
    <div class="page-info">
      <span
        ><b>1:</b> Silmek istediğiniz ürünün kategorisini seçtikten sonra açılan
        menüde ürün seçiniz.</span
      >
      <span
        ><b>2:</b> Dikkat! Silinen ürün geri kurtarılamaz. Ürün ekle menüsünden
        tekrar ekleyebilirsiniz.</span
      >
    </div>
    <div class="delete-input-group">
      <select v-model="selectedCategory" @change="takeProducts">
        <option value="" disabled>Lütfen bir kategori seçiniz</option>
        <option v-for="item in categories" :key="item._id" :value="item._id">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="delete-input-group" v-if="selectedCategory">
      <select v-model="selectedProduct">
        <option value="" disabled>Silinecek ürünü seçiniz</option>
        <option v-for="item in products" :key="item._id" :value="item">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="selected-product" v-if="selectedProduct">
      <span> <b>Silinecek Ürün</b> </span>
      <hr />
      <span><b>Ürün Adı:</b> {{ selectedProduct.name }}</span>
      <span><b>Ürün Kodu:</b> {{ selectedProduct.code }}</span>
      <span class="image"
        ><b>Ürün Resmi:</b>
        <img :src="selectedProduct.image | setImage" alt="" />
      </span>
    </div>
    <div class="loader" v-if="loaderStatus"></div>
    <div class="delete-message">{{ deleteMessage }}</div>
    <div class="delete-button" v-if="selectedProduct">
      <div class="button-group">
        <button @click="selectedProduct = ''">İptal</button>

        <button @click="deleteProduct()">Ürünü Sil</button>
      </div>
      <span class="caution">Bu işlem geri alınamaz!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteProduct",
  data() {
    return {
      selectedCategory: "",
      selectedProduct: "",
      loaderStatus: false,
      deleteMessage: "",
    };
  },
  created() {
    this.$store.dispatch("getCategories");
    console.log(navigator.language);
  },
  computed: {
    categories() {
      return this.$store.state.categories.navbarItems;
    },
    products() {
      return this.$store.state.admin.productsInCategory;
    },
  },
  methods: {
    takeProducts() {
      this.selectedProduct = "";
      this.$store.dispatch("getProductsInCategory", this.selectedCategory);
    },
    deleteProduct() {
      let data = {
        productID: this.selectedProduct._id,
        imageID: this.selectedProduct.imageID,
        category: this.selectedCategory.category,
      };
      this.loaderStatus = true;
      this.$store.dispatch("deleteProduct", data).then(() => {
        this.loaderStatus = false;
        this.selectedProduct = "";
        this.selectedCategory = "";
        if (this.$store.state.admin.deleteStatus) {
          this.deleteMessage = "Ürün başarıyla silindi";
        } else {
          this.deleteMessage = "Ürün silinirken bir hata oluştu";
        }
        setTimeout(() => {
          this.deleteMessage = "";
        }, 3000);
      });
    },
  },
  filters: {
    setImage: function (value) {
      return `${process.env.VUE_APP_IMAGEURL_ENDPOINT}/${value}?tr=w-500,h-400,cm-pad_resize,bg-000`;
    },
  },
};
</script>

<style lang="scss">
.delete-product-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .page-info {
    border: 2px solid #a8a7a7;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: bisque;
  }
  .delete-product-header {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid #777777;
    margin-bottom: 1rem;
    color: #777777;
  }
  .delete-input-group {
    width: 50%;
    display: flex;
    margin-bottom: 1rem;
    select {
      width: 100%;
      height: 40px;
      outline: none;
      border: #e5e5e5 solid 1px;
      border-radius: 10px;
      padding-left: 10px;
    }
  }
  .selected-product {
    display: flex;
    flex-direction: column;
    .image {
      width: 250px;
      img {
        width: 100%;
      }
    }
  }
  .delete-button {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    .button-group {
      button {
        height: 40px;
        width: 100px;
        outline: none;
        border: #e5e5e5 solid 1px;
        color: #777777;
        background-color: white;
        font-weight: 500;
        border-radius: 10px;
        &:nth-child(2) {
          margin-left: 10px;
          color: red;
        }
      }
    }
    .caution {
      color: red;
    }
  }
  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
