<template>
  <div class="admin-panel-container">
    <div class="options">
      <div class="sidebar">
       
        <div class="option-item" @click="optionPage('add-product')">
          Ürün Ekle
        </div>
        <div class="option-item" @click="optionPage('set-product')">
          Ürünü Düzenle
        </div>
        <div class="option-item" @click="optionPage('delete-product')">
          Ürün Sil
        </div>
        <div class="option-item" @click="optionPage('messages')">Mesajlar</div>
      </div>

      <div class="content">
    
        <SetProduct v-if="activePage == 'set-product'" />
        <AddProduct v-if="activePage == 'add-product'" />
        <Messages v-if="activePage == 'messages'" />
        <DeleteProduct v-if="activePage == 'delete-product'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SetProduct from "@/components/AdminPage/SetProduct.vue";
import AddProduct from "@/components/AdminPage/AddProduct.vue";
import Messages from "@/components/AdminPage/Messages.vue";
import DeleteProduct from "@/components/AdminPage/DeleteProduct.vue";

export default {
  name: "AdminPanel",
  components: {
    AddProduct,
    Messages,
    SetProduct,
    DeleteProduct,
  },
  data() {
    return {
      activePage: "add-product",
    };
  },
  created() {
    this.checkUserHasLogged();
  },
  computed: {
    ...mapState(["login"]),
  },
  methods: {
    ...mapActions(["checkUser"]),
    checkUserHasLogged() {
      let auth = sessionStorage.getItem("user");
      let uuid = sessionStorage.getItem("uuid");
      if (auth !== "true") {
        this.$router.push({ path: "/admin-login" }, () => {});
      }
      let data = {
        uuid: uuid,
      };
      this.checkUser(data).then(() => {
        if (!this.login.checkStatus) {
          this.$router.push({ path: "/admin-login" }, () => {});
        }
      });
    },
    optionPage(val) {
      this.activePage = val;
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/responsive.scss";
.admin-panel-container {
  width: 100%;
  min-height: 100vh;
  .options {
    display: flex;
    align-content: center;
    padding: 2rem;
    height: auto;
    width: 100%;

    @include xs {
      flex-direction: column;
    }
    @include sm {
      flex-direction: column;
    }
    @include md {
      flex-direction: column;
    }
    @include xl {
      justify-content: space-between;
    }
    @include lg {
      justify-content: space-between;
    }
    .sidebar {
      display: flex;
      flex-direction: row;
      width: 100%;
      @include xs {
        justify-content: space-between;
      }
      @include sm {
        justify-content: space-between;
      }
      @include md {
        justify-content: space-between;
      }
      @include xl {
        flex-direction: column;
        width: 200px;
      }
      @include lg {
        flex-direction: column;
        width: 200px;
      }
      .option-item {
        padding: 1rem 0;
        display: flex;
        border: 2px solid #e0e0e0;
        background-color: #f2f2f2;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        margin-bottom: 1rem;
        cursor: pointer;
        font-size: 1rem;
        @include xs {
          width: calc(100% / 5 - 10px);
        }
        @include sm {
          width: calc(100% / 5 - 10px);
        }
        @include md {
          width: calc(100% / 5 - 10px);
        }
      }
    }
    .content {
      margin-bottom: 2rem;
      height: auto;
      @include xs {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }
      @include md {
        width: 100%;
      }
      @include xl {
        flex-direction: column;
        width: calc(100% - (200px + 2rem));
      }
      @include lg {
        flex-direction: column;
        width: calc(100% - (200px + 2rem));
      }
    }
  }
}
</style>
